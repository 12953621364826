import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import FieldBase from '../shared/FieldBase'
import FusedFields from './fused-fields'
import NotificationBox from '../shared/NotificationBox'

import { getCountryName } from 'lib/helpers'

AddressBox.propTypes = {
  address: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  locked: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  title: PropTypes.string,
  setSelectedAddress: PropTypes.func,
  setAddressMode: PropTypes.func,
  showHeader: PropTypes.bool,
  containerClass: PropTypes.string,
  containerStyle: PropTypes.object,
  icon: PropTypes.string,
}

AddressBox.defaultProps = {
  address: {},
  mode: 'view',
  locked: false,
  selected: false,
  title: 'Address',
  setAddressMode: () => {},
  setSelectedAddress: () => {},
  showHeader: true,
  containerClass: '',
  containerStyle: { height: '100%', position: 'relative' },
  icon: '',
}

function AddressBox(props) {
  const [addressLocal, setAddressLocal] = useState(props.address)
  const countryNameLocal = useMemo(() => getCountryName(addressLocal.country), [addressLocal.country])

  useEffect(() => {
    setAddressLocal(props.address)
  }, [props.address])

  const _handleChoose = () => {
    props.setSelectedAddress()
  }

  const _handleUpdate = (field, value) => {
    setAddressLocal(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _handleCancel = () => {
    setAddressLocal(props.address)
    props.setAddressMode('view')
  }

  const { mode, locked, selected, address, title, showHeader, icon, containerClass, containerStyle, setAddressMode } = props

  const resolvedContainerClass = classNames({
    'cursor-pointer': mode === 'selection',
  }, containerClass)

  const fullname = [address.firstname, address.lastname].filter(val => val).join(' ')
  const city_state = [address.city, address.state].filter(val => val).join(', ')
  const location = [city_state, address.postalcode].filter(val => val).join(' ')

  const button_edit = <button className="btn btn-warning btn-xs pull-right" onClick={()=>props.setAddressMode('edit')}><span className="fa-fw fa fa-edit"/><span style={{fontWeight: 700}}> Edit</span></button>
  const button_choose = <button className="btn btn-warning btn-xs margin-top-5" onClick={_handleChoose}><span style={{fontWeight: 700}}> Choose this address</span></button>

  const complete = addressLocal.address_line1 && addressLocal.postalcode && addressLocal.country
  const incomplete_warning = !complete && !locked ? <NotificationBox size="micro" mode='warning'>Address is incomplete</NotificationBox> : null

  const view_address = <>
    <div className="margin-bottom-5">
      {fullname ? <>{fullname}<br /></> : null}
      {address.company ? <>{address.company}<br /></> : null}
      {address.address_line1 ? <>{address.address_line1}<br /></> : null}
      {address.address_line2 ? <>{address.address_line2}<br /></> : null}
      {location ? <>{location}<br /></> : null}
      {address.country ? <>{countryNameLocal}<br /></> : null}
      {address.phone ? <>{address.phone}<br /></> : null}
      {address.user_email ? <>{address.user_email}<br /></> : null}
    </div>
    {incomplete_warning}
  </>

  const edit_address =
    <FusedFields data={addressLocal} errors={null} onUpdate={_handleUpdate}>
      <div className="form-group">
        <label><i className="fa fa-fw fa-user"></i>First Name</label>
        <input name={"firstname"} className="form-control" type="text" />
      </div>
      <div className="form-group">
        <label><i className="fa fa-fw fa-user"></i>Last Name</label>
        <input name={"lastname"} className="form-control" type="text" />
      </div>
      <div className="form-group">
        <label><i className="fa fa-fw fa-building"></i>Company</label>
        <input name={"company"} className="form-control" type="text" maxLength={ 50 } />
      </div>
      <div className="form-group">
        <label><i className="fa fa-fw fa-map-marker"></i>Address</label>
        <input name={"address_line1"} placeholder="Street and number, PO Box, C/O" className="form-control" type="text" />
      </div>
      <div className="form-group">
        <input name={"address_line2"} placeholder="Apartment, suite, unit, floor, etc" className="form-control" type="text" />
      </div>
      <div className="form-group">
        <label>City</label>
        <input name={"city"} className="form-control" type="text" />
      </div>
      <div className="form-group">
        <label>State/Region</label>
        <input name={"state"} className="form-control" type="text" />
      </div>
      <div className="form-group">
        <label>Zip/Postal Code</label>
        <input name={"postalcode"} className="form-control" type="text" />
      </div>
      <div className="form-group">
        <label>Country</label>
        <FieldBase type='countryDropdown' name='country' standalone={true} />
      </div>
      <div className="form-group">
        <label><i className="fa fa-fw fa-phone"></i>Phone Number</label>
        <input name={"phone"} placeholder="Phone" className="form-control" type="text" />
      </div>
      {incomplete_warning}
      <button type='button' className='pull-right btn btn-u btn-u-sea' onClick={()=>setAddressMode('view', addressLocal)}>
        <span className='fa fa-check' />
        {' Update Address'}
      </button>
      <button type='button' className='btn btn-u btn-u-red' onClick={_handleCancel}>
        <span className='fa fa-times' />
        {' Cancel'}
      </button>
    </FusedFields>

  return <div className={resolvedContainerClass} style={containerStyle} {...(mode === 'selection' && { onClick: _handleChoose })}>
    {selected && <div style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', display: 'flex', alignItems: 'center', border: '1px solid #7ac142' }}><svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg></div>}
    <div className="form-general" style={{ height: '100%', fontWeight: '700', paddingBottom: '15px' }}>
      <div className="flex-standard-container">
        {/* <div className="flex-group flex-group-left" style={{ minWidth: 0 }}>
          <span className={"fa fa-fw fa-lg " + (true ? "fa-toggle-on text-primary" : "fa-toggle-off")} style={{ cursor: 'pointer', marginRight: '10px' }} onClick={(e) => _toggleActive(e, card)} />
          <span className="text-bold" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{'Use Primary Contact Info for Billing Address'}</span><span style={{ whiteSpace: 'nowrap' }}> ({'card.slidecount'} slides)</span>
        </div> */}
      </div>
      {showHeader ? <div>
        <h3 style={{marginTop: '0'}}>{icon !== '' && <i className={'fa fa-fw ' + icon} />}{title}{!locked && mode !== 'edit' ? button_edit : null}</h3>
      </div> : null}
      {mode === 'selection' && !selected && button_choose}
      {mode === 'edit' ? edit_address : view_address}
    </div>
  </div>
}

export default AddressBox
