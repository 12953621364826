import React from 'react'
import PropTypes from 'prop-types'

import AddressBox from '../shared/AddressBox'

import FormRow from '../shared/FormRow'

import { payment_types, invoice_options } from 'lib/constants'
import { formatMoney } from 'lib/utility'

InvoiceApprove.propTypes = {
  payment_total: PropTypes.number.isRequired,
  payment_type: PropTypes.string.isRequired,
  billing_address: PropTypes.object.isRequired,
  shipping_address: PropTypes.object.isRequired,
  shipping_options: PropTypes.object.isRequired,
  other_options: PropTypes.object.isRequired,
}

InvoiceApprove.defaultProps = {
  payment_type: '0',
}

function InvoiceApprove({ payment_total, payment_type, billing_address, shipping_address, shipping_options, other_options }) {
  const country = shipping_address.country

  const payment_type_text = payment_types.find(x => x.value === payment_type).text
  const payment_type_shortDescription = payment_types.find(x => x.value === payment_type).shortDescription

  const opt_included_icon = <span className='fa fa-plus text-success' />
  const opt_excluded_icon = <span className='fa fa-minus text-danger' />

  const options_carrier_selected = country === 'US' ? invoice_options.options_carrier_us.find(x => x.value === shipping_options.ship_carrier_preference) : invoice_options.options_carrier_other.find(x => x.value === shipping_options.ship_carrier_preference)
  const options_carrier_selected_text = options_carrier_selected ? options_carrier_selected.text : 'None selected - Please choose'
  const options_customs_declaration = invoice_options.options_customs_declaration.find(x => x.value === shipping_options.ship_customs_declaration)
  const options_customs_declaration_text = options_customs_declaration ? options_customs_declaration.text : 'None selected - Please choose'

  return <div>
    <FormRow>
      <div className="col-md-12" style={{ marginBottom: '15px' }}>
        <div className="alert-info fade in" style={{ padding: '5px' }}>
          <h3 style={{ margin: '0' }}><i className="fa fa-fw fa-arrow-circle-right"></i>Amount: {formatMoney(payment_total)} ({payment_type_shortDescription})</h3>
        </div>
      </div>
    </FormRow>
    <FormRow>
      {payment_type === '1' && <div className="col-md-6 show-only-in-desktop">
        <AddressBox
          locked={true}
          type="billing"
          title="Billing Address"
          address={billing_address}
        />
      </div>}
      <div className="col-md-6 show-only-in-desktop">
        <AddressBox
          locked={true}
          type="shipping"
          title="Shipping Address"
          address={shipping_address}
        />
      </div>
      {(payment_type === '2' || payment_type === '3' || payment_type === '6' || payment_type === '7') && <div className="col-md-6">
        {payment_type_shortDescription} instructions will appear on your invoice after approval
      </div>}
    </FormRow>
    <FormRow>
      <div className="col-md-12" style={{ marginTop: '15px' }}>
        <div className="form-general clearfix" style={{ paddingLeft: '10px' }}>
          <div className="pull-left" style={{ paddingRight: '15px', borderRight: '2px dotted #AAA', minHeight: '80px' }}>
            <span><i className='fa fa-truck fa-5x' /></span>
          </div>
          <div className="pull-left" style={{ paddingLeft: '15px' }}>
            <div><strong>Carrier: {options_carrier_selected_text}</strong></div>
            {country !== 'US' ? <div><strong>Customs: {options_customs_declaration_text}</strong></div> : null}
            <div>{other_options.inv_options_insurance === 1 ? opt_included_icon : opt_excluded_icon}<span style={{ fontWeight: '700' }}> {other_options.inv_options_insurance === 1 ? 'Insure Shipment' : 'Do Not Insure Shipment'}</span></div>
            {country === 'US' && <div>{other_options.inv_options_delivery_signature === 1 ? opt_included_icon : opt_excluded_icon}<span style={{ fontWeight: '700' }}> {other_options.inv_options_delivery_signature === 1 ? 'Delivery Signature Required' : 'Delivery Signature Not Required'}</span></div>}
          </div>
        </div>
      </div>
    </FormRow>
  </div>
}

export default InvoiceApprove
