import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import Cards from 'react-credit-cards-2'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import InvoiceLots from './InvoiceLots'
import InvoiceApprove from './InvoiceApprove'
import StripeCheckoutForm from './StripeCheckoutForm'
import ConfirmPaymentPanel from './ConfirmPaymentPanel'

import FormRow from '../shared/FormRow'
import FieldBase from '../shared/FieldBase'
import FieldText from '../shared/FieldText'
import FusedFields from '../shared/fused-fields'
import DataList from '../shared/DataList'
import DataListRow from '../shared/DataListRow'
import FormContainer from '../shared/FormContainer'

import NotificationBox from '../shared/NotificationBox'
import AddressBox from '../shared/AddressBox'
import StatusBox from '../shared/StatusBox'
import ConfirmBox from '../shared/ConfirmBox'

import { makeOptions, makeOptionsBoolean } from 'lib/utility'
import { getCountryName } from 'lib/helpers'
import { payment_types, stripePaymentOptions, company_address_line1, company_address_line2, modalStyles, invoice_options } from 'lib/constants'
import { validCreditCard } from 'lib/validation'
import { invoicePackages_specs } from 'lib/list_specifications'
import { calcInvoiceCosts } from 'lib/utility_invoice'

const stripePromise = loadStripe(__STRIPE_PUBLISHABLE_KEY__)

InvoiceContentUser.propTypes = {
  invoice: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setSelectedShippingAddress: PropTypes.func.isRequired,
  resolveShippingAddress: PropTypes.func.isRequired,
  setAddressMode: PropTypes.func.isRequired,
  setShippingOptionsMode: PropTypes.func.isRequired,
  approveInvoice: PropTypes.func.isRequired,
  approveInvoiceClear: PropTypes.func.isRequired,
  returnToAddressChooser: PropTypes.func.isRequired,
  confirmShippingChanges: PropTypes.func.isRequired,
  cancelShippingChanges: PropTypes.func.isRequired,
}

function InvoiceContentUser({ invoice, setFieldValue, setSelectedShippingAddress, resolveShippingAddress, setAddressMode, setShippingOptionsMode, approveInvoice, approveInvoiceClear, returnToAddressChooser, confirmShippingChanges, cancelShippingChanges }) {
  const [showPopup, setShowPopup] = useState(false)
  const [formErrors, setFormErrors] = useState(null)
  const [focusedField, setFocusedField] = useState('')
  const [invalidCreditCard, setInvalidCreditCard] = useState(false)
  const [confirmationTokenId, setConfirmationTokenId] = useState(null)
  const countryName = useMemo(() => getCountryName(invoice.content.address.shipping.data.country), [invoice.content.address.shipping.data.country])

  const viewMode = invoice.view.mode

  useEffect(() => {
    // ZZZ - in the future optimize so this doesn't get called on first render
    if (!showPopup) {
      approveInvoiceClear()
    }
  }, [showPopup, approveInvoiceClear])

  useEffect(() => {
    // console.log('useEffect-invalid-credit-card-reset-check')
    // remove invalid credit card message if CC fields are edited
    setInvalidCreditCard(false)
  }, [invoice.fields.CCname, invoice.fields.CCnumber, invoice.fields.CCexpiration, invoice.fields.CCcvv])

  // useEffect(() => {
  //   elements.update({amount: ?})
  // }, [stripePromise])

  const closePopup = () => {
    setShowPopup(false)
  }

  const _handleInputFocus = (field, value) => {
    setFocusedField(field === 'CCcvv' ? 'cvc' : field)
  }

  const _handleInputLeave = (field, value) => {
    setFocusedField('')
  }

  const _handleConfirm = ({ confirmationTokenId=null }={}) => {
    if (invoice.fields.payment_type === '1' && !validCreditCard(invoice.fields)) {
      console.log('Missing credit card info')
      setInvalidCreditCard(true)
    } else if (invoice.fields.payment_type === '5' && !confirmationTokenId) {
      // handle problems?
      console.log('Missing confirmationTokenId')
    } else {
      if (invoice.fields.payment_type === '5') {
        setConfirmationTokenId(confirmationTokenId)
      }
      setShowPopup(true)
    }
  }

  const _approveInvoice = () => {
    const calculatedCosts = calcInvoiceCosts({ primaryData: invoice.content.main, auxData: invoice.content.main, options: invoice.content.other_options.data, carrier: invoice.content.shipping_options.data.ship_carrier_preference, foreign: invoice.content.address.shipping.data.country !== 'US' })

    approveInvoice({
      paymentMetaData: {
        amount: calculatedCosts.total_due,
        amount_in_cents: calculatedCosts.total_due_in_cents,
        confirmationTokenId,
      }
    })
  }

  // console.log('invoice:', invoice)

  const data = invoice.content.main
  const fields = invoice.fields

  const address = invoice.content.address

  const shipping_options = invoice.content.shipping_options
  const other_options = invoice.content.other_options
  const foreign = address.shipping.data.country !== 'US'

  const calculatedCosts = calcInvoiceCosts({ primaryData: data, auxData: data, options: other_options.data, carrier: shipping_options.data.ship_carrier_preference, foreign })

  const creditcard_info_complete = fields.CCname && fields.CCnumber && fields.CCexpiration && fields.CCcvv ? true : false

  const shipping_carrier_change = shipping_options.data.ship_carrier_preference !== shipping_options.original.ship_carrier_preference
  const shipping_options_complete = shipping_options.data.ship_carrier_preference !== '' && shipping_options.data.ship_carrier_preference !== '0' && (!foreign || shipping_options.data.ship_customs_declaration !== 0)

  const notable_shipping_change = address.shipping.meta.notable_change || shipping_options.meta.notable_change || shipping_carrier_change
  const post_chooser_shipping_change = (address.shipping.meta.selected ? address.shipping.meta.diff_from_selected : address.shipping.meta.any_change) || shipping_options.meta.any_change || shipping_carrier_change
  const shipping_notice_normal = <span>Please note that if you make changes above to the shipping address or carrier, it will require review by OWA staff to determine if there are any resulting changes to shipping costs.</span>
  const shipping_notice_changed = <span>Your shipping information changes have been accepted.<br />Please allow some time for OWA Staff to review the changes.</span>
  const shipping_notice_change_warning = <span>Changing the shipping address or carrier will require review by OWA staff to determine if there are any resulting changes to shipping costs before you can approve this invoice.</span>
  const shipping_notice_change_info_base = <span><span className="text-bold">Please make any additional necessary changes to the above shipping address or carrier before submitting the invoice for review.</span><br />Press &quot;Confirm Changes&quot; to proceed</span>
  const shipping_notice_change_info_cancel = post_chooser_shipping_change ? <span>{address.shipping.meta.selected ? ', ' : 'or '}&quot;Cancel&quot; to undo any changes you have made on this page{address.shipping.meta.selected && ','}</span> : null
  const shipping_notice_change_info_return = <span> or &quot;Return to Address Chooser&quot; to start over completely</span>
  const shipping_notice_change_info = <>{shipping_notice_change_info_base}{shipping_notice_change_info_cancel}{shipping_notice_change_info_return}</>

  const shipping_mode_edit_active = address.shipping.meta.mode !== 'view' || shipping_options.meta.mode !== 'view'
  const mode_edit_active = shipping_mode_edit_active || address.billing.meta.mode !== 'view'
  const optionsReady = !mode_edit_active && address.shipping.meta.complete && shipping_options_complete

  const needsReviewNotification = <FormRow>
    <div className="col-sm-12">
      <NotificationBox mode='warning'>
        <span>This invoice is not ready yet.  It requires review by OWA staff before you can approve/pay it.  Check back later or wait for notification via email.</span>
      </NotificationBox>
    </div>
  </FormRow>

  const resolve_shipping_address_block = <FormRow>
    <div className="col-sm-12">
      <NotificationBox mode="info">
        <span>Your profile shipping address has changed since this invoice was created.<br /><span className="text-bold">Please choose which address you want us to use when shipping your item(s):</span></span>
      </NotificationBox>
      <FormRow>
        <div className="flexInDesktop">
          <div className="col-sm-6">
            <AddressBox
              locked={true}
              selected={address.shipping.meta.selected === 'original_data'}
              mode={'selection'}
              title="Address currently on invoice"
              address={address.shipping.original_data}
              setSelectedAddress={()=>setSelectedShippingAddress('original_data')}
            />
          </div>
          <div className="col-sm-6">
            <AddressBox
              locked={true}
              selected={address.shipping.meta.selected === 'profile_data'}
              mode={'selection'}
              title="Profile Shipping Address"
              address={address.shipping.profile_data}
              setSelectedAddress={()=>setSelectedShippingAddress('profile_data')}
            />
          </div>
        </div>
      </FormRow>
    </div>
    {address.shipping.meta.selected && <div className="col-sm-12">
      <NotificationBox size="small" mode="info">
        <span>{address.shipping.meta.selected === 'profile_data' && <span>Note that changing the address may require review. </span>}You can edit the address on the next page if necessary.</span>
      </NotificationBox>
        <button type='button' className='btn btn-u btn-u-sea' onClick={resolveShippingAddress}>
          <span className='fa fa-check' />
          {' Proceed to invoice'}
        </button>
    </div>}
  </FormRow>

  const options_carrier_selected = foreign ? invoice_options.options_carrier_other.find(x => x.value === shipping_options.data.ship_carrier_preference) : invoice_options.options_carrier_us.find(x => x.value === shipping_options.data.ship_carrier_preference)
  const options_carrier_selected_text = options_carrier_selected ? options_carrier_selected.text : 'None selected - Please choose'
  const options_customs_declaration = invoice_options.options_customs_declaration.find(x => x.value === shipping_options.data.ship_customs_declaration)
  const options_customs_declaration_text = options_customs_declaration ? options_customs_declaration.text : 'None selected - Please choose'
  const opt_included_icon = <span className='fa fa-plus text-success' />
  const opt_excluded_icon = <span className='fa fa-minus text-danger' />
  const shipping_section = <FormContainer addonBeforeStyle={{ width: '140px', textAlign: 'left', flexShrink: '0' }}>
    <FormRow>
      {viewMode === 'normal' ? <div className="col-sm-12 margin-bottom-10" style={{ fontSize: '18px', fontWeight: '700' }}>
        <div style={{ backgroundColor: '#f6da97', paddingLeft: '5px', fontFamily: 'Open Sans' }}>
          <i className="fa fa-fw fa-arrow-circle-right"></i>Step 1: Verify Shipping Address and Carrier
        </div>
      </div> : null}
      <div className="flexInDesktop">
        <div className="col-sm-6">
          <AddressBox
            locked={!invoice.content.ready || invoice.content.approved}
            icon='fa-truck'
            mode={address.shipping.meta.mode}
            title="Shipping Address"
            address={address.shipping.data}
            setAddressMode={(mode, address)=>setAddressMode('shipping', mode, address)}
          />
        </div>
        <div className="col-sm-6">
          {!address.shipping.data.country ? <NotificationBox size="small" mode="warning" containerClass="form-group">
            <div>Shipping country must be set<br />before options can be chosen!</div>
          </NotificationBox> :
          <>
            {viewMode === 'normal' ? <>
              <FusedFields data={shipping_options.data} onUpdate={setFieldValue} options={{ group: 'shipping_options' }}>
                <FormRow>
                  <FieldBase type="select" name='ship_carrier_preference' label="Carrier Preference" groupClassName='col-lg-12' groupStyle={{ marginBottom: '0' }}>
                    {makeOptions(foreign ? invoice_options.options_carrier_other : invoice_options.options_carrier_us, { filterHidden: true })}
                  </FieldBase>
                  <div className="text-bold" style={{ color: '#888', marginTop: '0', marginLeft: '15px', marginBottom: '10px' }}>Recommended Shipper by Old World Auctions</div>
                </FormRow>
                {foreign && <FormRow>
                  <FieldBase type="select" name='ship_customs_declaration' label="Customs Declaration" groupClassName='col-lg-12' data-type="int">
                    {makeOptions(invoice_options.options_customs_declaration)}
                  </FieldBase>
              </FormRow>}
              </FusedFields>
              </> :
              <>
              <div className="form-group col-sm-12">
                <label className="control-label">Carrier Preference</label>
                <div style={{ marginTop: '0', marginBottom: '10px' }}>{options_carrier_selected_text}</div>
                {/* <div className="text-bold" style={{ color: '#888', marginTop: '0', marginLeft: '0', marginBottom: '10px' }}>Recommended Shipper by Old World Auctions</div> */}
                {foreign && <>
                  <label className="control-label">Customs Declaration</label>
                  <div style={{marginTop: '0', marginBottom: '10px'}}>{options_customs_declaration_text}</div>
                </>}
                {viewMode !== 'preview' && <>
                  <div>{other_options.data.inv_options_insurance === 1 ? opt_included_icon : opt_excluded_icon}<span style={{ fontWeight: '700' }}> {other_options.data.inv_options_insurance === 1 ? 'Insure Shipment' : 'Do Not Insure Shipment'}</span></div>
                  {!foreign && <div>{other_options.data.inv_options_delivery_signature === 1 ? opt_included_icon : opt_excluded_icon}<span style={{ fontWeight: '700' }}> {other_options.data.inv_options_delivery_signature === 1 ? 'Delivery Signature Required' : 'Delivery Signature Not Required'}</span></div>}
                </>}
              </div>
            </>}
          </>}
        </div>
      </div>
      <FusedFields data={shipping_options.data} errors={formErrors} onUpdate={setFieldValue} options={{ group: 'shipping_options' }} classes='col-sm-12'>
        <FormRow>
          <div className="form-group col-sm-6">
            <label className="control-label">Admin Notes</label>
            <div style={{marginTop: '0', marginBottom: '10px'}}>{shipping_options.data.ship_notes_admin ? shipping_options.data.ship_notes_admin : '-none-'}</div>
          </div>
          {viewMode === 'normal' ? <FieldBase type="textarea" name='ship_notes_user' label='User Notes' groupClassName='col-sm-6' style={{height: '75px'}} maxLength={700} />
          : <div className="form-group col-sm-6">
              <label className="control-label">User Notes</label>
              <div style={{marginTop: '0', marginBottom: '10px'}}>{shipping_options.data.ship_notes_user ? shipping_options.data.ship_notes_user : '-none-'}</div>
            </div>}
        </FormRow>
      </FusedFields>
    </FormRow>
    {viewMode === 'normal' || invoice.content.changed ? <FormRow>
        <div className="col-sm-12">
        {invoice.content.changed ? <NotificationBox size="small" mode="success">
          {shipping_notice_changed}
        </NotificationBox> : (notable_shipping_change ? <>
          <NotificationBox size="small" mode="warning">
            {shipping_notice_change_warning}
          </NotificationBox>
          <NotificationBox size="small" mode="info">
            {shipping_notice_change_info}
          </NotificationBox>
          {!address.shipping.meta.complete &&  <NotificationBox size="small" mode="danger">Shipping Address must be complete before changes can be submitted</NotificationBox>}
        </> : <NotificationBox mode="info">
          {shipping_notice_normal}
        </NotificationBox>)}
        {!invoice.content.changed && !mode_edit_active && notable_shipping_change ?
          <div className="col-sm-12">
            {!invoice.actions.shippingChanges.processing && <FormRow>
              {address.shipping.meta.selected && <button type='button' className='btn btn-u btn-u-blue margin-right-20' onClick={returnToAddressChooser}>
                <span className='fa fa-arrow-left' />
                {' Return to Address Chooser'}
              </button>}
              {post_chooser_shipping_change && <button type='button' className='btn btn-u btn-u-red margin-right-20' onClick={cancelShippingChanges}>
                <span className='fa fa-times' />
                {' Cancel Changes'}
              </button>}
              {address.shipping.meta.complete && <button type='button' className='btn btn-u btn-u-sea' onClick={confirmShippingChanges}>
                <span className='fa fa-check' />
                {' Confirm Changes'}
              </button>}
            </FormRow>}
            <FormRow>
              <StatusBox data={invoice.actions.shippingChanges} className="margin-top-20" />
            </FormRow>
          </div>
        : null}
      </div>
    </FormRow> : null}
  </FormContainer>

  const options_section = <FormRow style={{ marginBottom: '5px' }}>
    <div className="col-sm-12 margin-bottom-10" style={{ fontSize: '18px', fontWeight: '700' }}>
      <div style={{ backgroundColor: '#f6da97', paddingLeft: '5px', fontFamily: 'Open Sans' }}>
        <i className="fa fa-fw fa-arrow-circle-right"></i>Step 2: Shipping Options and Extras
      </div>
    </div>
    <FusedFields data={other_options.data} errors={formErrors} onUpdate={setFieldValue} options={{ group: 'other_options' }} classes='col-sm-12'>
      <FormContainer addonBeforeStyle={{ width: '140px', textAlign: 'left', flexShrink: '0' }}>
        <FormRow>
          <FieldBase type="select" name='inv_options_insurance' data-type='int' addonBefore='Insurance' groupClassName='col-sm-6'>
            {makeOptionsBoolean()}
          </FieldBase>
          <div className="form-group col-md-6">
            <span className="input-group color-darkred" style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>If you decline insurance, please be aware that you are solely responsible for any loss or damage to the package while it is in-transit.</span>
          </div>
        </FormRow>
        {!foreign && <FormRow>
          <FieldBase type="select" name='inv_options_delivery_signature' data-type='int' addonBefore='Delivery Signature' groupClassName='col-sm-6'>
            {makeOptionsBoolean()}
          </FieldBase>
          <div className="form-group col-md-6">
            <span className="input-group color-darkred" style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>A delivery signature is recommended for higher value packages and/or those going to large metropolitan areas.</span>
          </div>
        </FormRow>}
        <FormRow>
          <FieldBase type="select" name='inv_options_digital_images' data-type='int' addonBefore='Add digital images' groupClassName='col-sm-6'>
            {makeOptionsBoolean()}
          </FieldBase>
          <div className="form-group col-md-6">
            <span className="input-group" style={{ display: 'flex', alignItems: 'center', height: '34px', marginTop: '-5px' }}>At $5 per lot it is a great tool for documenting your collection!</span>
          </div>
        </FormRow>
      </FormContainer>
    </FusedFields>
  </FormRow>

  let post_payment_instructions = null
  const resolved_payment_type = viewMode === 'approved' ? data.inv_payment_type : +fields.payment_type
  switch (resolved_payment_type) {
    case 2:
      post_payment_instructions = <div className="col-sm-12 margin-bottom-10">
        <div style={{border: "1px solid rgb(170, 170, 170)", borderRadius: "10px", padding: "10px 20px"}}>
          <h3 className="text-bold" style={{borderBottom: "1px solid #aaa"}}>Check Instructions</h3>
          <p className="text-bold">You have chosen to pay by check. Please send the check as soon as possible to the following address:</p>
          <div className="col-sm-12">
            <p>
              <span className="text-bold">Old World Auctions</span><br />
              <span className="text-bold">{company_address_line1}</span><br />
              <span className="text-bold">{company_address_line2}</span><br />
            </p>
          </div>
          <p className="text-bold">We will ship your items as soon as your funds are received.</p>
        </div>
      </div>
      break;
    case 3:
      post_payment_instructions = <div className="col-sm-12 margin-bottom-10">
        <div style={{border: "1px solid rgb(170, 170, 170)", borderRadius: "10px", padding: "10px 20px"}}>
          <h3 className="text-bold" style={{borderBottom: "1px solid #aaa"}}>Wire Transfer Instructions (USD)</h3>
          <p className="text-bold">You have choosen to pay by Wire. Please send the wire as soon as possible to the following account:</p>
          <div className="col-sm-6">
            <p>
              Account holder: <span className="text-bold">Old World Auctions</span><br />
              Account number: <span className="text-bold">0000159243958</span><br />
              Bank routing number: <span className="text-bold">051404260</span><br />
              Swift number: <span className="text-bold">BRBTUS33</span><br />
            </p>
          </div>
          <div className="col-sm-6">
            <p>
              Bank name: <span className="text-bold">Truist Bank</span><br />
              Bank address:<br /><span className="text-bold">11704 W. Broad Street<br />
              Richmond, VA 23233</span><br />
            </p>
          </div>
          <p className="text-bold">We will ship your items as soon as your funds are received.</p>
        </div>
      </div>
      break;
    case 6:
      post_payment_instructions = <div className="col-sm-12 margin-bottom-10">
        <div style={{border: "1px solid rgb(170, 170, 170)", borderRadius: "10px", padding: "10px 20px"}}>
          <h3 className="text-bold" style={{borderBottom: "1px solid #aaa"}}>Zelle Instructions</h3>
          <p className="text-bold">To pay your invoice by Zelle, please send the total amount due on the invoice to <a href={"mailto:jon@oldworldauctions.com"}>jon@oldworldauctions.com</a>.  Thank you.</p>
          <p className="text-bold">We will ship your items as soon as your funds are received.</p>
        </div>
      </div>
      break;
    case 7:
      post_payment_instructions = <div className="col-sm-12 margin-bottom-10">
        <div style={{border: "1px solid rgb(170, 170, 170)", borderRadius: "10px", padding: "10px 20px"}}>
          <h3 className="text-bold" style={{borderBottom: "1px solid #aaa"}}>Wire Transfer Instructions (EUR, GBR, CAD, AUD)</h3>
          <p className="text-bold">To pay by your invoice in Euros (EUR), Pounds (GBR), Canadian Dollars (CAD) or Australian Dollars (AUD), please email <a href={"mailto:jon@oldworldauctions.com"}>jon@oldworldauctions.com</a> and he will provide our local bank details.  Thank you.</p>
          <p className="text-bold">We will ship your items as soon as your funds are received.</p>
        </div>
      </div>
      break;
    default:
      break;
  }

  const lot_section = <FormRow>
    {viewMode === 'normal' ? <div className="col-sm-12 margin-bottom-10" style={{ fontSize: '18px', fontWeight: '700' }}>
      <div style={{ backgroundColor: '#f6da97', paddingLeft: '5px', fontFamily: 'Open Sans' }}>
        <i className="fa fa-fw fa-arrow-circle-right"></i>Step 3: Review Purchases
      </div>
    </div> : null}
    {viewMode === 'approved' && post_payment_instructions}
    <div className="col-sm-12">
      <InvoiceLots mode="user" invoice={invoice} />
    </div>
  </FormRow>

  const payment_section = <FormRow style={{ marginTop: '20px' }}>
    <div className="col-sm-12 margin-bottom-10" style={{ fontSize: '18px', fontWeight: '700' }}>
      <div style={{ backgroundColor: '#f6da97', paddingLeft: '5px', fontFamily: 'Open Sans' }}>
        <i className="fa fa-fw fa-arrow-circle-right"></i>Step 4: Choose Payment Type
      </div>
    </div>
    <div className="col-sm-12 margin-bottom-10">
      <FusedFields data={fields} errors={formErrors} onUpdate={setFieldValue}>
        <FormRow>
          <FieldBase type="select" name='payment_type' addonBefore='Payment Type' groupClassName='col-sm-6'>
            {makeOptions(payment_types, {  bitFilterValue: data.payment_methods_accepted, numBits: payment_types.length, customOption: { value: '0', text: '*** Please Select ***' } })}
          </FieldBase>
        </FormRow>
      </FusedFields>
      {fields.payment_type === '1' && <div style={{marginBottom: '15px'}}>
        <FormRow style={{marginBottom: '15px'}}>
          <div className="col-sm-12">
            <div className='grid-standard-container'>
            <form action="#" className="form-general">
              <FusedFields data={fields} errors={formErrors} onUpdate={setFieldValue} onFocus={_handleInputFocus} onLeave={_handleInputLeave}>
                <FieldText name="CCname" label="Name on Credit Card" addonBefore={<i className="fa fa-fw fa-user" />} placeholder="Full name on Card" maxLength={255} groupStyle={{ marginBottom: '10px' }} />
                <FieldText name="CCnumber" label="Card Number" addonBefore={<i className="fa fa-fw fa-credit-card" />} placeholder="Card Number" maxLength={19} groupStyle={{ marginBottom: '10px' }} data-type="credit-card-number" />
                <FieldText name="CCexpiration" label="Card Expiration" addonBefore={<i className="fa fa-fw fa-calendar" />} placeholder="MM/YY" maxLength={7} groupStyle={{ marginBottom: '10px' }} data-type="credit-card-expiration" />
                <FieldText name="CCcvv" label="Card CVV Code" addonBefore={<i className="fa fa-fw fa-asterisk" />} placeholder="CVV" maxLength={10} groupStyle={{ marginBottom: '10px' }} />
                {invalidCreditCard && <NotificationBox size="micro" mode='danger'>Missing/invalid credit card information</NotificationBox>}
              </FusedFields>
            </form>
            <div id="card-wrapper">
              <Cards
                number={fields.CCnumber}
                name={fields.CCname}
                expiry={fields.CCexpiration}
                cvc={fields.CCcvv}
                focused={focusedField}
              />
            </div>
          </div>
        </div>
        </FormRow>
        <FormRow>
          <div className="col-sm-12">
            <AddressBox
              icon="fa-credit-card-alt"
              mode={address.billing.meta.mode}
              title="Billing Address"
              address={address.billing.data}
              setAddressMode={(mode, address) => setAddressMode('billing', mode, address)}
            />
          </div>
        </FormRow>
        <ConfirmPaymentPanel completePaymentInfo={creditcard_info_complete} completeShippingAddress={address.shipping.meta.complete} completeShippingOptions={shipping_options_complete} billingAddressRequired={true} completeBillingAddress={address.billing.meta.complete} confirmAction={_handleConfirm} noticePaymentInfoIncomplete="Complete credit card info" />
      </div>}
      {fields.payment_type === '5' && <div style={{ marginBottom: '15px' }}>
        <Elements stripe={stripePromise} options={{...stripePaymentOptions, amount: calculatedCosts.total_due_in_cents}}>
          <StripeCheckoutForm userInfo={data} address={address} completeShippingOptions={shipping_options_complete} confirmAction={_handleConfirm} />
        </Elements>
      </div>}
      {fields.payment_type === '0' && <NotificationBox mode='info'><span>Choose payment type to proceed...</span></NotificationBox>}
      {(fields.payment_type !== '0' && fields.payment_type !== '1' && fields.payment_type !== '5') && <ConfirmPaymentPanel completePaymentInfo={true} completeShippingAddress={address.shipping.meta.complete} completeShippingOptions={shipping_options_complete} confirmAction={_handleConfirm} />}
    </div>
  </FormRow>

  const packagesData = <DataList
    useTable={true}
    tableStyle={{ fontSize: '13px' }}
    showHeader={true}
    showTopPagingControl={false}
    showBottomPagingControl={false}
    data={invoice.content.packages}
    totalItemCount={invoice.content.package_count}
    metadata={invoicePackages_specs.user}
    datakey='id'
    headerColumnVisibleKey={address.shipping.data.country}
    pageSize={400}
  >
    <DataListRow />
  </DataList>

  const content = <div className="page-base page-user">
    {viewMode === 'resolve-shipping-address' ?
      resolve_shipping_address_block :
    <>
      {viewMode === 'preview' ? needsReviewNotification : null}
      {shipping_section}
      {viewMode === 'normal' && !shipping_mode_edit_active && !notable_shipping_change ? options_section : null}
      {!shipping_mode_edit_active && !notable_shipping_change || invoice.content.changed ? lot_section : null}
      {viewMode === 'normal' && !shipping_mode_edit_active && !notable_shipping_change ? payment_section : null}
    </>}
    {invoice.content.package_count > 0 && <div className="flex-generic-container child-center">
      <div style={{ maxWidth: '600px' }}>
        <div className="text-center text-bold">Shipped Packages</div>
        {packagesData}
      </div>
    </div>}
    <div className="text-center margin-top-10">
      <div className="contact-info-bar">{"Old World Auctions, " + company_address_line1 + ', ' + company_address_line2} <span className="bullet">&bull;</span> 1-804-290-8090</div>
    </div>
  </div>

  return <div>
    {content}
    <Modal
      closeTimeoutMS={150}
      isOpen={showPopup}
      onRequestClose={() => {}}
      style={modalStyles}
      contentLabel="Approve Invoice " >
      <ConfirmBox
        title={'Confirm Approval - Invoice #' + data.auction_id + '-' + data.id}
        style={{ maxWidth: '600px' }}
        confirm_button_text="Approve"
        close_button_text="Go Back/Edit"
        confirm_button_action={_approveInvoice}
        close_button_action={closePopup}
        data={invoice.actions.approval}
        resultType="persist"
      >
        <InvoiceApprove
          payment_total={calculatedCosts.total_due}
          payment_type={fields.payment_type}
          billing_address={address.billing.data}
          shipping_address={address.shipping.data}
          shipping_options={shipping_options.data}
          other_options={other_options.data}
        />
      </ConfirmBox>
    </Modal>
  </div>
}

export default InvoiceContentUser
