import React from 'react'
import PropTypes from 'prop-types'

import FormRow from '../shared/FormRow'
import NotificationBox from '../shared/NotificationBox'

ConfirmPaymentPanel.propTypes = {
  completePaymentInfo: PropTypes.bool.isRequired,
  completeShippingAddress: PropTypes.bool.isRequired,
  completeShippingOptions: PropTypes.bool.isRequired,
  completeBillingAddress: PropTypes.bool,
  billingAddressRequired: PropTypes.bool,
  noticePaymentInfoIncomplete: PropTypes.string,
  noticeStatic: PropTypes.string,
  confirmAction: PropTypes.func.isRequired,
}

function ConfirmPaymentPanel({ completePaymentInfo=false, completeShippingAddress=false, completeShippingOptions=false, completeBillingAddress=false, billingAddressRequired=false, noticePaymentInfoIncomplete="Payment information", noticeStatic=null, confirmAction }) {
  return <FormRow>
    <div className="col-sm-12" style={{ marginTop: '15px' }}>
      {(completePaymentInfo && completeShippingAddress && completeShippingOptions && (!billingAddressRequired || completeBillingAddress)) ? <button type='submit' className='btn btn-u btn-u-dark-blue' onClick={confirmAction}>
        {'Confirm Payment '}
        <span className='fa fa-arrow-right' />
      </button> : <>
        <NotificationBox mode='warning' size='small'>
          <div className="text-bold">Provide the following required info to proceed:</div>
          {!completePaymentInfo && <div>{noticePaymentInfoIncomplete}</div>}
          {!completeShippingAddress && <div>Shipping Address</div>}
          {!completeShippingOptions && <div>Shipping Options</div>}
          {(billingAddressRequired && !completeBillingAddress) && <div>Billing Address</div>}
        </NotificationBox>
      </>}
      {noticeStatic && <NotificationBox mode='info' size='small'><span>{noticeStatic}</span></NotificationBox>}
    </div>
  </FormRow>
}

export default ConfirmPaymentPanel
