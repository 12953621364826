import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js'

import ConfirmPaymentPanel from './ConfirmPaymentPanel'
import NotificationBox from '../shared/NotificationBox'

StripeCheckoutForm.propTypes = {
  userInfo: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  completeShippingOptions: PropTypes.bool.isRequired,
  confirmAction: PropTypes.func.isRequired,
}

export default function StripeCheckoutForm({ userInfo, address, completeShippingOptions, confirmAction }) {
  const stripe = useStripe()
  const elements = useElements()
  const [accountConnected, setAccountConnected] = useState(false)

  // console.log('elements:', elements)
  const paymentElement = elements && elements.getElement('payment')
  console.log('paymentElement:', paymentElement)

  const [errorMessage, setErrorMessage] = useState()

  const handleError = (error) => {
    setErrorMessage(error.message)
  }

  const paymentElement_handleOnChange = (e) => {
    setAccountConnected(e.complete)
  }

  const handleServerResponse = async (response) => {
    if (response.error) {
      // Show error from server on payment form
    } else if (response.status === "requires_action") {
      // Use Stripe.js to handle the required next action
      const {
        error,
        paymentIntent
      } = await stripe.handleNextAction({
        clientSecret: response.clientSecret
      })

      if (error) {
        // Show error from Stripe.js in payment form
      } else {
        // Actions handled, show success message
      }
    } else {
      // No actions needed, show success message
    }
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()
    setErrorMessage(null)

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit()
    if (submitError) {
      handleError(submitError)
      return
    }

    // Create the ConfirmationToken using the details collected by the Payment Element
    // and additional shipping information
    const {error, confirmationToken} = await stripe.createConfirmationToken({
      elements,
      params: {
        shipping: {
          name: [address.shipping.data.firstname, address.shipping.data.lastname].filter(Boolean).join(" "),
          address: {
            line1: address.shipping.data.address_line1,
            line2: address.shipping.data.address_line2,
            city: address.shipping.data.city,
            state: address.shipping.data.state,
            country: address.shipping.data.country,
            postal_code: address.shipping.data.postalcode,
          },
        }
      }
    })

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
      handleError(error)
      return
    }

    // // call server-side handler
    // // Create the PaymentIntent
    // const res = await fetch("/create-confirm-intent", {
    //   method: "POST",
    //   headers: {"Content-Type": "application/json"},
    //   body: JSON.stringify({
    //     confirmationTokenId: confirmationToken.id,
    //   }),
    // })

    // const data = await res.json()

    // // Handle any next actions or errors. See the Handle any next actions step for implementation.
    // handleServerResponse(data)

    // call confirmAction here and pass confirmationToken.id
    confirmAction({ confirmationTokenId: confirmationToken.id })
  }

  const paymentElementOptions = {
    layout: "accordion",
    defaultValues: {
      billingDetails: {
        name: [userInfo.user_firstname, userInfo.user_lastname].filter(Boolean).join(" "),
        email: userInfo.user_email,
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} onChange={paymentElement_handleOnChange} />
      {errorMessage && <NotificationBox mode='warning' size='small' containerClass='margin-top-10'><span>{errorMessage}</span></NotificationBox>}
      <ConfirmPaymentPanel completePaymentInfo={accountConnected} completeShippingAddress={address.shipping.meta.complete} completeShippingOptions={completeShippingOptions} noticePaymentInfoIncomplete="Connect bank account to proceed..." confirmAction={handleSubmit} />
    </form>
  )
}
