import deepEqual from 'deep-equal'

import { processQuery, get } from 'lib/api'
import { defineAction, status_defaults, view_defaults } from 'lib/state_defaults'
import { validDateOrNull } from 'lib/validation'
import { formatDate } from 'lib/utility'
import { calcInvoiceCosts } from 'lib/utility_invoice'

const base = 'owa/invoice/'

const DATA_INVOICE = defineAction(base, 'DATA_INVOICE', 'async')
const APPROVE_INVOICE = defineAction(base, 'APPROVE_INVOICE', 'async', { customActions: ['clear'] })
const ADMIN_UPDATE_INVOICE = defineAction(base, 'ADMIN_UPDATE_INVOICE', 'async')
const DATA_PACKAGES = defineAction(base, 'DATA_PACKAGES', 'async')
const PACKAGE_ADD = defineAction(base, 'PACKAGE_ADD', 'async')
const PACKAGE_REMOVE = defineAction(base, 'PACKAGE_REMOVE', 'async')
const PACKAGES_EMAIL = defineAction(base, 'PACKAGES_EMAIL', 'async')
const SHIPPING_CHANGES = defineAction(base, 'SHIPPING_CHANGES', 'async', { customActions: ['cancel'] })

const FIELD_SET_VALUE = base.concat('FIELD_SET_VALUE')
const INVOICE_SET_MODE = base.concat('INVOICE_SET_MODE')
const INVOICE_SELECT_SHIPPING_ADDRESS = base.concat('INVOICE_SELECT_SHIPPING_ADDRESS')
const INVOICE_RESOLVE_SHIPPING_ADDRESS = base.concat('INVOICE_RESOLVE_SHIPPING_ADDRESS')
const ADDRESS_SET_MODE = base.concat('ADDRESS_SET_MODE')
const SHIP_OPTIONS_SET_MODE = base.concat('SHIP_OPTIONS_SET_MODE')
const SHIP_OPTIONS_RESET = base.concat('SHIP_OPTIONS_RESET')

const packagesFields_defaults = {
  ship_date: '',
  carrier: '',
  tracking: '',
  customs_declaration: 0,
  insure_full: 0,
  signature_required: 0,
}

const content_defaults = {
  init: false,
  init_date: null,
  ready: false,
  ready_date: null,
  changed: false,
  approved: false,
  paid: false,
  main: {},
  lots: [],
  lot_count: 0,
  packages: [],
  package_count: 0,
  address: {
    billing: {
      data: {},
      original_data: {},
      meta: {
        mode: 'view',
        complete: false,
        notable_change: false,
        any_change: false,
      }
    },
    shipping: {
      data: {},
      original_data: {},
      profile_data: {},
      meta: {
        mode: 'view',
        complete: false,
        notable_change: false,
        any_change: false,
        selected: null,
        diff_from_selected: false,
      }
    }
  },
  shipping_options: {
    data: {
      ship_carrier_preference: '',
      ship_customs_declaration: 0,
      ship_notes_user: '',
      ship_notes_admin: '',
    },
    original: {
      ship_carrier_preference: '',
      ship_customs_declaration: 0,
      ship_notes_user: '',
      ship_notes_admin: '',
    },
    meta: {
      mode: 'view',
      complete: false,
      notable_change: false,
      any_change: false,
    },
  },
  other_options: {
    data: {
      inv_options_digital_images: 0,
      inv_options_insurance: 1,
      inv_options_insurance_custom: 0,
      inv_options_delivery_signature: 0,
    },
  },
  admin_info: {
    data: {
      inv_ship_date: '',
      inv_ship_tracking: '',
      amount_shipping: 0,
      amount_tax_override: 0,
      amount_tax: 0,
      amount_deposit: 0,
      amount_misc_desc: '',
      amount_misc: 0,
      inv_options_digital_images: 0,
      inv_options_insurance: 1,
      inv_options_insurance_custom: 0,
      inv_options_delivery_signature: 0,
      inv_approved: 0,
      inv_paid: 0,
    },
    original: {
      inv_ship_date: '',
      inv_ship_tracking: '',
    },
    meta: {
      complete: false,
      notable_change: false,
      any_change: false,
      loaded: false,
    },
  },
  packagesFields: {
    data: packagesFields_defaults,
  }
}

const fields_defaults = {
  payment_type: '0',
  CCname: '',
  CCnumber: '',
  CCexpiration: '',
  CCcvv: '',
}

const actions_defaults = {
  approval: status_defaults,
  shippingChanges: status_defaults,
  adminUpdate: status_defaults,
  getPackages: status_defaults,
  packageAdd: status_defaults,
  packageRemove: status_defaults,
  packagesEmail: status_defaults,
}

const initialState = {
  meta: status_defaults,
  view: view_defaults,
  content: content_defaults,
  actions: actions_defaults,
  fields: fields_defaults,
}

function buildAddress(data, prefix) {
  return {
    firstname: data[prefix + '_firstname'] || null,
    lastname: data[prefix + '_lastname'] || null,
    company: data[prefix + '_company'] || null,
    address_line1: data[prefix + '_address_line1'] || null,
    address_line2: data[prefix + '_address_line2'] || null,
    city: data[prefix + '_city'] || null,
    state: data[prefix + '_state'] || null,
    postalcode: data[prefix + '_postalcode'] || null,
    country: data[prefix + '_country'] || null,
    phone: data[prefix + '_phone'] || null,
    user_email: data.user_email || null,
  }
}

function buildShippingOptions(data, prefix) {
  return {
    ship_carrier_preference: data[prefix + '_carrier_preference'],
    ship_customs_declaration: data[prefix + '_customs_declaration'],
    ship_notes_user: data[prefix + '_notes_user'],
    ship_notes_admin: data[prefix + '_notes_admin'],
  }
}

function buildAdminInfo(data) {
  return {
    inv_ship_date: validDateOrNull(data.inv_ship_date),
    inv_ship_tracking: data.inv_ship_tracking,
    amount_shipping: data.amount_shipping,
    amount_tax_override: data.amount_tax_override,
    amount_tax: data.amount_tax,
    amount_deposit: data.amount_deposit,
    amount_misc_desc: data.amount_misc_desc,
    amount_misc: data.amount_misc,
    inv_options_digital_images: data.inv_options_digital_images,
    inv_options_insurance: data.inv_options_insurance,
    inv_options_insurance_custom: data.inv_options_insurance_custom,
    inv_options_delivery_signature: data.inv_options_delivery_signature,
    inv_approved: data.inv_approved,
    inv_paid: data.inv_paid,
  }
}

function shippingCountryChangeCheck(value, state, dispatch) {
  if (value !== state.invoice.content.address.shipping.data.country) {
    dispatch({ type: SHIP_OPTIONS_RESET })
  }
}

function notableAddressChangeCheck(incomingAddress, originalAddress) {
  return incomingAddress.country !== originalAddress.country || incomingAddress.state !== originalAddress.state ? true : false
}

function addressCompleteCheck(a) {
  return a.address_line1 && a.postalcode && a.country ? true : false
}

// ZZZ - address refactor in progress
function addressNotEmpty(a) {
  return a.firstname || a.lastname || a.company || a.phone || a.address_line1 || a.address_line2 || a.city || a.state || a.postalcode || a.country ? true : false
}

function notableShippingOptionChangeCheck(incomingOptions, originalOptions) {
 return incomingOptions.ship_carrier_preference !== originalOptions.ship_carrier_preference
}

function shippingOptionsCompleteCheck(options, address) {
  return options.ship_carrier_preference !== '' && (address.country === 'US' || options.ship_customs_declaration !== 0)
}

function mapInvoiceData(payload, mode) {
  const main = payload.main
  const init = main.inv_init === 1 ? true : false
  const ready = main.inv_ready === 1 ? true : false
  const approved = main.inv_approved === 1 ? true : false
  // const shipping_prefix = mode === 'admin' && !init ? 'ship' : 'inv_ship'
  // const shipping_options_prefix = mode === 'admin' && !init ? 'ship' : 'inv_ship'
  // check for ready and approved flag as well to catch any records that have had their init flag reset - should not happen except in development/testing
  const shipping_prefix = !init && !ready && !approved ? 'ship' : 'inv_ship'
  const shipping_options_prefix = !init && !ready && !approved ? 'ship' : 'inv_ship'
  const profile_address_shipping = buildAddress(main, 'ship')
  const address_shipping = buildAddress(main, shipping_prefix)
  const address_billing = buildAddress(main, 'bill')
  const shipping_options = buildShippingOptions(main, shipping_options_prefix)
  const admin_info = mode === 'admin' ? buildAdminInfo(main) : {}

  return {
    ...content_defaults,
    mode: mode,
    invoice_id: main.id,
    init: init,
    init_date: validDateOrNull(main.inv_init_date),
    changed: main.inv_changed === 1 ? true : false,
    changed_date: validDateOrNull(main.inv_changed_date),
    ready: main.inv_ready === 1 ? true : false,
    ready_date: validDateOrNull(main.inv_ready_date),
    approved: approved,
    approved_date: validDateOrNull(main.inv_approved_date),
    paid: main.inv_paid === 1 ? true : false,
    paid_date: validDateOrNull(main.inv_paid_date),
    prev: payload.prev && payload.prev.id ? payload.prev.id : null,
    next: payload.next && payload.next.id ? payload.next.id : null,
    main: main,
    lots: payload.lots,
    lot_count: payload.lot_count,
    packages: payload.packages,
    package_count: payload.package_count,
    address: {
      billing: {
        ...content_defaults.address.billing,
        data: address_billing,
        original_data: address_billing,
        meta: {
          ...content_defaults.address.billing.meta,
          complete: addressCompleteCheck(address_billing),
          notEmpty: addressNotEmpty(address_billing),
        }
      },
      shipping: {
        ...content_defaults.address.shipping,
        data: address_shipping,
        original_data: address_shipping,
        profile_data: profile_address_shipping,
        meta: {
          ...content_defaults.address.shipping.meta,
          complete: addressCompleteCheck(address_shipping),
        }
      }
    },
    shipping_options: {
      data: shipping_options,
      original: shipping_options,
      meta: {
        ...content_defaults.shipping_options.meta,
        complete: shippingOptionsCompleteCheck(shipping_options, address_shipping),
      },
    },
    other_options: {
      data: {
        inv_options_digital_images: main.inv_options_digital_images,
        inv_options_insurance: main.inv_options_insurance,
        inv_options_insurance_custom: main.inv_options_insurance_custom,
        inv_options_delivery_signature: main.inv_options_delivery_signature,
      },
    },
    admin_info: {
      data: admin_info,
      original: admin_info,
      meta: {
        complete: true,
        notable_change: false,
        any_change: false,
        loaded: mode === 'admin',
      },
    },
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA_INVOICE.request:
      return {
        ...state,
        meta: {
          ...status_defaults,
          processing: true,
        }
      }
    case DATA_INVOICE.fail:
      return {
        ...state,
        content: content_defaults,
        meta: {
          ...status_defaults,
          ...action.meta,
        },
        view: view_defaults,
      }
    case DATA_INVOICE.success:
    {
      const content = mapInvoiceData(action.payload, action.mode)
      const shippingAddressMismatch = !deepEqual(content.address.shipping.data, content.address.shipping.profile_data)
      return {
        ...state,
        content: content,
        meta: {
          ...status_defaults,
          ...action.meta,
        },
        view: {
          ...view_defaults,
          mode: content.approved ? 'approved' : (!content.ready ? 'preview' : (shippingAddressMismatch ? 'resolve-shipping-address' : 'normal')),
        },
        ...(action.reset && { actions: actions_defaults }),
        fields: fields_defaults,
      }
    }
    case FIELD_SET_VALUE:
      if (action.options.group) {
        const subgroup = action.options.subgroup ? action.options.subgroup : null

        const data = {
          ...(subgroup ? state.content[action.options.group][subgroup].data : state.content[action.options.group].data),
          [action.field]: action.value,
        }

        return {
          ...state,
          content: {
            ...state.content,
            [action.options.group]: {
              ...state.content[action.options.group],
              ...(subgroup ? { [subgroup]: {
                ...state.content[action.options.group][subgroup],
                data,
              } } : { data }),
            }
          }
        }
      } else {
        return {
          ...state,
          fields: {
            ...state.fields,
            [action.field]: action.value,
          }
        }
      }
    case SHIP_OPTIONS_RESET:
      return {
        ...state,
        content: {
          ...state.content,
          shipping_options: {
            ...state.content.shipping_options,
            data: {
              ...state.content.shipping_options.data,
              ship_carrier_preference: '',
              ship_customs_declaration: 0,
            },
            meta: {
              ...state.content.shipping_options.meta,
              complete: false,
              notable_change: notableShippingOptionChangeCheck(state.content.shipping_options.data, state.content.shipping_options.original),
              any_change: !deepEqual(state.content.shipping_options.data, state.content.shipping_options.original),
            },
          },
          other_options: {
            ...state.content.other_options,
            data: {
              ...state.content.other_options.data,
              inv_options_delivery_signature: 0,
            },
          },
        }
      }
    case INVOICE_SET_MODE:
      return {
        ...state,
        view: {
          ...state.view,
          mode: action.mode,
        }
      }
    case INVOICE_SELECT_SHIPPING_ADDRESS:
      return {
        ...state,
        content: {
          ...state.content,
          address: {
            ...state.content.address,
            shipping: {
              ...state.content.address.shipping,
              meta: {
                ...state.content.address.shipping.meta,
                selected: action.value,
              }
            }
          }
        }
      }
    case INVOICE_RESOLVE_SHIPPING_ADDRESS:
      return {
        ...state,
        content: {
          ...state.content,
          address: {
            ...state.content.address,
            shipping: {
              ...state.content.address.shipping,
              data: state.content.address.shipping[state.content.address.shipping.meta.selected],
              meta: {
                ...state.content.address.shipping.meta,
                complete: addressCompleteCheck(state.content.address.shipping[state.content.address.shipping.meta.selected]),
                notable_change: notableAddressChangeCheck(state.content.address.shipping[state.content.address.shipping.meta.selected], state.content.address.shipping.original_data),
                any_change: !deepEqual(state.content.address.shipping[state.content.address.shipping.meta.selected], state.content.address.shipping.original_data),
              }
            }
          }
        },
        view: {
          ...state.view,
          mode: 'normal',
        },
      }
    case ADDRESS_SET_MODE:
      return {
        ...state,
        content: {
          ...state.content,
          address: {
            ...state.content.address,
            [action.address_type]: {
              ...state.content.address[action.address_type],
              data: action.address,
              meta: {
                ...state.content.address[action.address_type].meta,
                mode: action.mode,
                complete: addressCompleteCheck(action.address),
                notable_change: action.notable_change,
                any_change: action.any_change,
                diff_from_selected: state.content.address[action.address_type].meta.selected ? !deepEqual(action.address, state.content.address[action.address_type][state.content.address[action.address_type].meta.selected]) : false,
              }
            }
          }
        }
      }
    case SHIP_OPTIONS_SET_MODE:
      return {
        ...state,
        content: {
          ...state.content,
          shipping_options: {
            ...state.content.shipping_options,
            data: action.options,
            meta: {
              ...state.content.shipping_options.meta,
              mode: action.mode,
              complete: action.complete,
              notable_change: action.notable_change,
              any_change: action.any_change,
            }
          }
        }
      }
    case APPROVE_INVOICE.clear:
      return {
        ...state,
        actions: {
          ...state.actions,
          approval: status_defaults,
        }
      }
    case APPROVE_INVOICE.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          approval: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case APPROVE_INVOICE.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          approval: {
            ...status_defaults,
            ...action.meta,
          }
        },
        content: {
          ...state.content,
          approved: true,
          main: {
            ...state.content.main,
            inv_payment_type: action.payload.payment_type,
          }
        },
        view: {
          ...view_defaults,
          mode: 'approved',
        },
      }
    case APPROVE_INVOICE.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          approval: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case SHIPPING_CHANGES.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          shippingChanges: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case SHIPPING_CHANGES.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          shippingChanges: {
            ...status_defaults,
            ...action.meta,
          }
        },
        content: {
          ...state.content,
          changed: true,
          ready: false,
        },
        view: {
          ...view_defaults,
          mode: 'preview',
        },
      }
    case SHIPPING_CHANGES.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          shippingChanges: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case SHIPPING_CHANGES.cancel:
    {
      const revertAddress = (action.returnToAddressChooser || !state.content.address.shipping.meta.selected) ? state.content.address.shipping.original_data : state.content.address.shipping[state.content.address.shipping.meta.selected]
      return {
        ...state,
        content: {
          ...state.content,
          address: {
            ...state.content.address,
            shipping: {
              ...state.content.address.shipping,
              data: revertAddress,
              meta: {
                ...state.content.address.shipping.meta,
                mode: 'view',
                complete: addressCompleteCheck(revertAddress),
                notable_change: notableAddressChangeCheck(revertAddress, state.content.address.shipping.original_data),
                any_change: !deepEqual(revertAddress, state.content.address.shipping.original_data),
                selected: action.returnToAddressChooser ? null : state.content.address.shipping.meta.selected,
                diff_from_selected: false,
              }
            }
          },
          shipping_options: {
            ...state.content.shipping_options,
            data: state.content.shipping_options.original,
            meta: {
              ...state.content.shipping_options.meta,
              mode: 'view',
              complete: shippingOptionsCompleteCheck(state.content.shipping_options.original, state.content.address.shipping.original_data),
              notable_change: false,
              any_change: false,
            }
          }
        },
        view: {
          ...view_defaults,
          mode: action.returnToAddressChooser ? 'resolve-shipping-address' : state.view.mode,
        },
        ...(action.returnToAddressChooser && {fields: fields_defaults}),
      }
    }
    case ADMIN_UPDATE_INVOICE.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          adminUpdate: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case ADMIN_UPDATE_INVOICE.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          adminUpdate: {
            ...status_defaults,
            ...action.meta,
          }
        },
        content: {
          ...state.content,
          init: true,
          init_date: state.content.init ? state.content.init_date : formatDate(),
          ready: action.payload.mode === 1 ? true : action.payload.mode === 2 ? false : state.content.ready,
          ready_date: action.payload.mode === 1 ? formatDate() : action.payload.mode === 2 ? null : state.content.ready_date,
          changed: action.payload.mode === 1 ? false : state.content.changed,
          approved: action.payload.flags.approved === 1 ? true : false,
          paid: action.payload.mode === 1 ? false : (action.payload.flags.paid === 1 ? true : false),
        }
      }
    case ADMIN_UPDATE_INVOICE.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          adminUpdate: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA_PACKAGES.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          getPackages: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case DATA_PACKAGES.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          getPackages: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA_PACKAGES.success:
      return {
        ...state,
        content: {
          ...state.content,
          packages: action.payload.data,
          package_count: action.payload.count,
          packagesFields: {
            data: packagesFields_defaults,
          }
        },
        actions: {
          ...state.actions,
          getPackages: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case PACKAGE_ADD.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          packageAdd: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case PACKAGE_ADD.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          packageAdd: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case PACKAGE_ADD.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          packageAdd: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case PACKAGE_REMOVE.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          packageRemove: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case PACKAGE_REMOVE.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          packageRemove: {
            ...status_defaults,
            ...action.meta,
          }
        },
      }
    case PACKAGE_REMOVE.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          packageRemove: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case PACKAGES_EMAIL.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          packagesEmail: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case PACKAGES_EMAIL.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          packagesEmail: {
            ...status_defaults,
            ...action.meta,
          }
        },
      }
    case PACKAGES_EMAIL.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          packagesEmail: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    default:
      return state
  }
}

export function setFieldValue(field, value, options) {
  return (dispatch, getState) => {
    // ZZZ - is there a better way to do this check?
    if (field === 'country' && options.group === 'address' && options.subgroup === 'shipping') {
      shippingCountryChangeCheck(value, getState(), dispatch)
    }

    if (field === 'amount_tax_override' && value === 1) {
      const state = getState().invoice.content
      const calc_tax = calcInvoiceCosts({ primaryData: state.main, auxData: state.admin_info.data, options: state.admin_info.data, carrier: state.shipping_options.data.ship_carrier_preference, foreign: state.address.shipping.data.country !== 'US', state: state.address.shipping.data.state, mode: 'admin' }).tax
      dispatch({ type: FIELD_SET_VALUE, field: 'amount_tax', value: calc_tax, options })
    }

    return dispatch({
      type: FIELD_SET_VALUE,
      field: field,
      value: value,
      options: options,
    })
  }
}

export function setInvoiceMode(mode) {
  return {
    type: INVOICE_SET_MODE,
    mode: mode,
  }
}

export function setSelectedShippingAddress(value) {
  return {
    type: INVOICE_SELECT_SHIPPING_ADDRESS,
    value: value,
  }
}

export function resolveShippingAddress() {
  return {
    type: INVOICE_RESOLVE_SHIPPING_ADDRESS,
  }
}

export function setAddressMode(address_type, mode, updatedAddress=null) {
  return (dispatch, getState) => {
    const st = getState().invoice.content.address
    let address = st[address_type].data
    let notable_change = st[address_type].meta.notable_change
    let any_change = st[address_type].meta.any_change
    if (mode === 'view' && updatedAddress !== null) {
      address = updatedAddress
      notable_change = notableAddressChangeCheck(updatedAddress, st[address_type].original_data)
      any_change = !deepEqual(updatedAddress, st[address_type].original_data)
      if (address_type === 'shipping') {
        shippingCountryChangeCheck(updatedAddress.country, getState(), dispatch)
      }
    }
    dispatch({
      type: ADDRESS_SET_MODE,
      mode: mode,
      address_type: address_type,
      address: address,
      notable_change: notable_change,
      any_change: any_change,
    })
  }
}

export function setShippingOptionsMode(mode, updatedShippingOptions=null) {
  return (dispatch, getState) => {
    const state = getState().invoice
    let options = state.content.shipping_options.data
    let notable_change = state.content.shipping_options.meta.notable_change
    let any_change = state.content.shipping_options.meta.any_change
    let complete = state.content.shipping_options.meta.complete
    if (mode === 'view' && updatedShippingOptions !== null) {
      options = updatedShippingOptions
      notable_change = notableShippingOptionChangeCheck(options, state.content.shipping_options.original)
      any_change = !deepEqual(options, state.content.shipping_options.original)
      complete = shippingOptionsCompleteCheck(options, state.content.address.shipping.data)
    }
    dispatch({
      type: SHIP_OPTIONS_SET_MODE,
      mode: mode,
      options: options,
      complete: complete,
      notable_change: notable_change,
      any_change: any_change,
    })
  }
}

export function approveInvoiceClear() {
  return {
    type: APPROVE_INVOICE.clear,
  }
}

export function returnToAddressChooser() {
  return {
    type: SHIPPING_CHANGES.cancel,
    returnToAddressChooser: true,
  }
}

export function cancelShippingChanges() {
  return {
    type: SHIPPING_CHANGES.cancel,
  }
}

export function getData(mode, invoice_id, reset=true) {
  return (dispatch, getState) => {
    const state = getState()
    const query = state.invoices.content.info.location[mode] && state.invoices.content.info.location[mode].query
    const querydata = processQuery(query, { whitelist: ['auction_id', 'user_lastname', 'country_type', 'ready', 'changed', 'approved', 'paid'] })
    const endpoint = (mode === 'admin' ? '/api/admin/invoice/' : '/api/user/invoice/') + invoice_id + querydata
    return dispatch(
      get({
        types: DATA_INVOICE,
        endpoint: endpoint,
        inject: { mode, reset },
      })
    )
  }
}

export function approveInvoice({ paymentMetaData }) {
  return (dispatch, getState) => {
    const state = getState()
    const endpoint = '/api/user/invoice/' + state.invoice.content.invoice_id

    return dispatch(
      get({
        types: APPROVE_INVOICE,
        endpoint: endpoint,
        config: {
          method: 'post',
          body: { paymentMetaData, content: state.invoice.content, fields: state.invoice.fields },
        },
      })
    )
  }
}

export function confirmShippingChanges() {
  return (dispatch, getState) => {
    const state = getState()

    return dispatch(
      get({
        types: SHIPPING_CHANGES,
        endpoint: '/api/user/invoice/' + state.invoice.content.invoice_id,
        config: {
          method: 'post',
          body: { shippingChanges: true, content: state.invoice.content, fields: state.invoice.fields },
        },
      })
    )
  }
}

export function adminUpdateInvoice(mode) {
  // ZZZ - do we need field validation for admin invoice page???
  return (dispatch, getState) => {
    const state = getState()

    return dispatch(
      get({
        types: ADMIN_UPDATE_INVOICE,
        endpoint: '/api/admin/invoice/' + state.invoice.content.invoice_id,
        config: {
          method: 'post',
          body: { mode: mode, content: state.invoice.content, fields: state.invoice.fields },
        },
      })
    )
  }
}

export function getPackages(invoice_id) {
  return (dispatch) => {
    return dispatch(
      get({
        types: DATA_PACKAGES,
        endpoint: '/api/admin/invoice/packages/' + invoice_id,
      })
    )
  }
}

export function packageAdd(packageData, invoice_id) {
  return (dispatch, getState) => {
    const state = getState().invoice

    return dispatch(
      get({
        types: PACKAGE_ADD,
        endpoint: '/api/admin/invoice/package/add',
        config: {
          method: 'post',
          body: { packageData, invoice_id },
        },
        postSuccess: () => {
          return dispatch(getPackages(state.content.invoice_id))
        }
      })
    )
  }
}

export function packageRemove(invoice_package_id) {
  return (dispatch, getState) => {
    const state = getState().invoice

    return dispatch(
      get({
        types: PACKAGE_REMOVE,
        endpoint: '/api/admin/invoice/package/remove',
        config: {
          method: 'post',
          body: { invoice_package_id },
        },
        postSuccess: () => {
          return dispatch(getPackages(state.content.invoice_id))
        }
      })
    )
  }
}

export function packagesEmail() {
  return (dispatch, getState) => {
    const state = getState().invoice

    return dispatch(
      get({
        types: PACKAGES_EMAIL,
        endpoint: '/api/admin/invoice/packages/' + state.content.invoice_id + '/send-email',
        config: {
          method: 'post',
          body: { content: state.content },
        },
        postSuccess: () => {
          return dispatch(getPackages(state.content.invoice_id))
        }
      })
    )
  }
}
